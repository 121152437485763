import fonts from '../../styles/typography.module.scss';
import cx from 'classnames';

import PriceCard from '../PriceCard/PriceCard';
import s from './ProSubscription.module.scss';
import React, { ReactNode } from 'react';
import Badge from '../Badge';

interface CheckListProps {
	items: ReactNode[];
}

const CheckList = ({ items }: CheckListProps) => {
	let i = 0;
	return (
		<ul className={s.list}>
			{items.map((item) => (
				<li key={i++}>{item}</li>
			))}
		</ul>
	);
};

interface SubscriptionMonthlyPriceProps {
	priceLabel: string;
}

const SubscriptionMonthlyPrice = ({
																		priceLabel
																	}: SubscriptionMonthlyPriceProps) => {
	return (
		<div className={s.priceSection}>
			<div className={s.row}>
				<p className={fonts.caption1}>Price</p>
				<p className={cx(s.priceText, fonts.body2)}>{priceLabel}</p>
			</div>
			<div className={s.row}>
				<p className={fonts.caption1}>Billing period</p>
				<p className={cx(s.priceText, fonts.body2)}>Monthly</p>
			</div>
		</div>
	);
};

const ProSubscription = ({ plans }: { plans: any[] }) => {
	return (
		<div
			className="flex flex-col lg:flex-row items-center lg:items-start gap-10 justify-evenly">
			{plans && [...plans]
				.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
				.map((plan) => (
					<PriceCard
						key={plan.pk}
						ribbonText={plan.ribbonText}
						viaContact={plan.viaContact}
						name={plan.name}
						title={plan.description}
						linkToDocs={plan.linkToDocs}
						primaryColor={plan.primaryColor ?? '#456b95'}
						secondaryColor={plan.secondaryColor ?? '#1e4670'}
						actionButton={{
							label: 'Subscribe Now',
							onClick: () => {
								window.open(process.env.COMAND_CENTER_URL ?? 'https://cc.collab.land');
							}
						}}
					>
						<div className={s.cardContent}>
							<CheckList
								items={plan.features
									.filter((f: any) => !f.hide)
									.map((feature: any) => {
										if (feature.subfeatures) {
											return (
												<div>
													{feature.description}
													<ul
														className={s.secondaryList}
														style={{ listStyleType: 'disc' }}
													>
														{feature.subfeatures.map((f: string) => (
															<li key={f}>{f}</li>
														))}
													</ul>
												</div>
											);
										}
										if (feature.isNew) {
											return <span className={'font-extrabold'}>{feature.description}
												<Badge className={'ml-1'} color={plan.primaryColor ?? '#456b95'}>
                              NEW
                            </Badge></span>;
										}
										return feature.description;
									})}
							/>
							{plan.price && (
								<SubscriptionMonthlyPrice priceLabel={`$${plan.price} USD`} />
							)}
						</div>
					</PriceCard>
				))}
		</div>
	);
};

export default ProSubscription;
